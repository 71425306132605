@import "../../../assets/sass/global/variable-media";

.container {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.caption {
  margin-top: 0;
  margin-bottom: 75px;

  text-transform: uppercase;

  font-size: 1.875rem;

  display: flex;

  strong {
    margin-right: 5px;
  }

  @media (max-width: $media-mobile-phone) {
    font-size: 1.5rem;
    margin-bottom: 70px;
  }
}

.img {
  position: relative;

  width: 39.1%;
  flex-shrink: 0;

  min-width: 748px;

  &::before {
    content: "";
    display: block;
    padding-top: 11.8%;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }

  @media (max-width: $media-mobile-phone) {
    width: 42.18%;

    min-width: 300px;

    &::before {
      padding-top: 12.03%;
    }
  }
}

.img__1 {
  position: absolute;
  top: -5px;
  left: 35.6%;
  z-index: 1;

  width: 20.9%;
  flex-shrink: 0;

  opacity: 0;

  min-width: 157px;

  animation-name: animate-img;
  animation-duration: 2s;
  animation-fill-mode: forwards;

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }

  @media (max-width: $media-mobile-phone) {
    width: 22.2%;

    min-width: 72px;

    &::before {
      padding-top: 100%;
    }
  }
}

.img__2 {
  position: absolute;
  top: -5px;
  left: 10.15%;
  z-index: 1;

  width: 20.9%;
  flex-shrink: 0;

  opacity: 0;

  min-width: 157px;

  animation-name: animate-img;
  animation-delay: 1.5s;
  animation-duration: 2s;
  animation-fill-mode: forwards;

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }

  @media (max-width: $media-mobile-phone) {
    width: 22.2%;

    min-width: 72px;

    &::before {
      padding-top: 100%;
    }
  }
}

.img__3 {
  position: absolute;
  top: 30px;
  right: 23.6%;
  z-index: 1;

  opacity: 0;

  width: 50px;
  flex-shrink: 0;

  animation-name: animate-img;
  animation-delay: 3s;
  animation-duration: 2s;
  animation-fill-mode: forwards;

  &::before {
    content: "";
    display: block;
    padding-top: 216%;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }

  @media (max-width: $media-mobile-phone) {
    top: 15px;

    width: 6.79%;

    min-width: 22px;

    &::before {
      padding-top: 218.18%;
    }
  }
}

@keyframes animate-img {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

