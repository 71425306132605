@import "../../assets/sass/global/variable-media";

.containerWrapper {
  position: absolute;
  top: 255px;
  left: 0px;
  width: 100%;

  z-index: 5;

  display: flex;
  padding: 0px 0px;
  transition: opacity 0.2s;
  opacity: 1;
  @media (min-width: $media-mobile-table-2) and (max-width: $media-mobile-table) {
    position: fixed;
    top: auto;
    bottom: 30px;
    width: calc(100vw - 383px);
    padding: 0px 21px;
  }
  @media (max-width: $media-mobile-table-2) {
    position: fixed;
    top: 390px;
    padding: 0px 0px 0px 16px;

    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  }
  @media (max-width: $media-mobile-phone) {
    position: fixed;
    top: 215px;
    padding: 0px 16px;
  }
}

.containerWrapperOpacityAnimation {
  opacity: 0;
}

.containerWrapperMinimalScrolledVersion {
  @media (max-width: $media-mobile-table-2) {
    position: fixed;
    top: 170px;
    padding-left: 20px;
  }
  @media (max-width: $media-mobile-phone) {
    position: fixed;
    top: 72px;
    padding-left: 20px;
  }
}

.containerWrapperBags {
  @media (max-width: $media-mobile-phone) {
    top: 75px;
    padding: 0px 30px;
  }
}

.basketItemRemove {
  appearance: none;
  border: none;
  padding: 0;

  font-size: 10px;
  color: var(--tough-white);

  height: 26px;
  width: 26px;

  cursor: pointer;

  overflow: hidden;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
  background-image: url("../../assets/images/icon/remove.svg");

  z-index: 1;

  @media (max-width: $media-mobile-phone) {
    height: 18.35px;
    width: 18.35px;
  }
}

.container {
  width: 100%;
  z-index: 1;
  margin-bottom: 4.7%;

  display: flex;
  justify-content: center;

  padding-right: 106px;
  padding-left: 50px;

  @media (max-width: $media-mobile-phone) {
    padding-right: 25px;
    padding-left: 25px;
    margin-bottom: 0px;
  }
}

.basketList {
  list-style: none;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: auto;

  padding: 0px 2px;
  overflow-x: scroll;
  overflow-y: hidden;

  @media (max-width: $media-mobile-table) {
    column-gap: 6px;
  }

  @media (max-width: $media-mobile-table-2) {
    padding-right: 16px;
    width: 100%;
    max-width: 100%;
    justify-content: flex-start;
    overflow-y: hidden;
    column-gap: 5px;
  }

  @media (max-width: $media-mobile-phone) {
    width: 100%;
    max-width: 100%;
    justify-content: flex-start;
    overflow-y: hidden;
    column-gap: 6px;
  }
}

.basketItem {
  width: 120px;
  height: 120px;
  min-width: 120px;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: -1px;
  border: 1px dashed transparent;

  @media (max-width: $media-mobile-phone) {
    width: 64px;
    height: 64px;
    min-width: 64px;
    min-height: 64px;
  }
}

.basketItemScrolledVersion {
  @media (max-width: $media-mobile-table-2) {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;

    display: flex;
    flex-direction: row;
  }
  @media (max-width: $media-mobile-phone) {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;

    display: flex;
    flex-direction: row;
  }
}

.basketItem .img__container {
  padding: 13px;
}

.basketContainer {
  padding: 13px;

  @media (max-width: $media-mobile-phone) {
    padding: 5px;
  }
}

.basketImg {
  max-width: 85px;
  max-height: 77px;

  @media (max-width: $media-mobile-phone) {
    max-width: 40px;
    max-height: 40px;
  }
}

.basketQuantity {
  font-weight: 700;
  margin-top: 3px;

  @media (max-width: $media-mobile-phone) {
    font-size: 12px;
  }
}

.basketQuantity__scrolled {
  @media (max-width: $media-mobile-table-2) {
    font-size: 12px;
  }
}

.basketQuantityMobile {
  display: none;
  font-weight: 700;
  margin-top: 3px;

  @media (max-width: $media-mobile-phone) {
    font-size: 12px;
  }
}

.basketQuantityScrolledVersion {
  @media (max-width: $media-mobile-phone) {
    margin: 0px;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-left: 12px;
  padding-right: 8px;
  padding-top: 8px;
  align-items: flex-start;

  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: $media-mobile-phone) {
    padding-top: 2.82px;
    padding-right: 2.82px;
    padding-left: 4px;
  }
}

.headerHidden {
  @media (max-width: $media-mobile-table-2) {
    padding-left: 0px;
  }
  @media (max-width: $media-mobile-phone) {
    display: none;
  }
}

.basketQuantityMobileShow {
  @media (max-width: $media-mobile-phone) {
    display: block;
  }
}

.basketImgScrolledVersion {
  @media (max-width: $media-mobile-table-2) {
    height: 35px;
  }
  @media (max-width: $media-mobile-phone) {
    height: 16px;
  }
}
