@import "../../assets/sass/global/variable-media";

.dragContainer {
  display: flex;
  justify-content: center;
  width: calc(100vw - 461px);
  height: 100%;
  //height: 60%;
  //max-height: 400px;

  @media (max-width: $media-mobile-table) {
    width: calc(100vw - 20px);
  }
}

.dragBlock {
  position: relative;
  width: 100%;
  flex-shrink: 0;
}

.dragInner {
  display: flex;
  flex-direction: row;
}

.dragInner {
  display: flex;
  flex-direction: row;
}

.dragMain {
  position: relative;
  width: 287px;
  flex-shrink: 0;
}

.dragMain::before {
  content: "";
  display: block;
  padding-top: 126.13%;
}

.dragMainImg {
  max-height: 100%;
  max-width: 100%;
}

.dragList {
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;

  height: 100%;
  width: 100%;
}

.primaryImageContainer {
  width: 40%;
  position: relative;
}

.primaryImage {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.dragItem {
  position: absolute;
  user-select: none;
}


.dragImg {
  height: 100%;
}


.product__showImgTrash {
  appearance: none;
  position: absolute;
  right: 30px;
  bottom: 15%;

  height: 105px;
  width: 105px;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  background-color: transparent;
  border: none;

  @media (max-width: $media-mobile-table) {
    right: 10px;
    bottom: 10px;

    box-sizing: content-box;
    padding: 8px;

    height: 35px;
    width: 35px;
  }
}

.product__showImgTrash_inactive {
  opacity: 0.5;
}

.product__showImgTrashIcon {
  height: auto;
  width: 35px;
}
