.animation {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 8;

  height: 100vh;
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--tough-white);
}

.animation__hide {
  appearance: none;
  background-color: transparent;
  border: none;

  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 1;

  display: inline-flex;
  height: 18px;
  width: 18px;

  box-sizing: content-box;
  padding: 5px;

  cursor: pointer;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto auto;
  background-clip: padding-box;
  background-image: url("../../assets/images/icon/close.svg");

  span {
    display: none;
  }
}
