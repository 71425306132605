.container {
  position: absolute;
  z-index: 1;

  width: 215px;

  padding-top: 20px;
  padding-right: 10px;
  padding-bottom: 23px;
  padding-left: 10px;

  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  background-color: var(--tough-orange);
}

.caption {
  font-weight: 700;
  line-height: 1.13;

  text-align: center;

  margin-bottom: 8px;
}

.desc {
  font-size: 0.875rem;
  line-height: 1.29;

  text-align: center;
}

.triangle {
  content: "";
  display: inline-flex;
  position: absolute;
  height: 12px;
  width: 12px;
  background-color: var(--tough-orange);

}

.triangleTop {
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.triangleRight {
  top: 50%;
  right: 0;
  transform: translateX(50%) translateY(-50%) rotate(45deg);
}

.triangleBottom {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(50%) rotate(45deg);
}

.triangleLeft {
  top: 50%;
  left: 0;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.triangleRightCorner {
  top: 0;
  right: 10px;
  transform: translateY(-50%) rotate(45deg);
}
