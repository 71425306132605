@import "../../assets/sass/global/variable-media";

.product {
  display: flex;
  flex-grow: 1;
}

.product__row {
  display: flex;
  flex-grow: 1;

  @media (max-width: $media-mobile-table-2) {
    max-width: 100%;
    flex-direction: column;
  }
}

.product__rowColumn {
  flex-shrink: 0;
  user-select: none;
}

.product__rowColumnKneePad .product__navItem {
  width: 50%;
}

.product__rowColumn_view {
  display: flex;
  flex-direction: column;

  flex-grow: 1;

  @media (min-width: $media-mobile-phone) and (max-width: $media-mobile-table-2) {
    height: 513px;
    max-height: 513px;
    transition: opacity 0.2s;
  }

  @media (max-width: $media-mobile-phone) {
    max-height: 310px;
    opacity: 1;

    transition: opacity 0.2s;
  }
}

.product__rowColumn_view__scrolled__tablet {
  @media (min-width: $media-mobile-phone) and (max-width: $media-mobile-table-2) {
    height: 225px;
    max-height: 225px;
  }
}

.product__rowColumn_view__opacity {
  @media (max-width: $media-mobile-table-2) {
    opacity: 0;
  }
}

.product__rowColumn_view__bags {
  @media (max-width: $media-mobile-phone) {
    max-height: 400px;
  }
}

.product__rowColumn_panel {
  position: relative;
  width: 383px;

  display: flex;
  flex-direction: column;

  border-left: 1px solid var(--tough-gray-2);

  @media (max-width: $media-mobile-table-2) {
    width: 100%;
    flex: 1;

    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  }
}

.product__config {
  display: flex;
  justify-content: space-between;
  align-items: center;

  //margin-left: calc((100vw - 89.5%) / 2);
  padding-left: calc(5.25vw + 10px);
  padding-right: 39.5px;
  opacity: 1;
  height: auto;

  @media (max-width: $media-mobile-table) {
    padding-right: 20px;
    padding-left: 30px;
  }

  @media (max-width: $media-mobile-table-2) {
    width: 100%;

    padding-right: 18px;
    padding-left: 15px;
  }
}

.product__config__hidden {
  @media (max-width: $media-mobile-phone) {
    opacity: 0;
    height: 0px;
  }
}

.product__configPrev {
  position: relative;
  height: 26px;
  width: 26px;
  flex-shrink: 0;

  svg {
    max-width: 100%;
    max-height: 100%;
  }
}

.product__configNav {
  display: flex;
}

.product__configNavShare {
  position: relative;
  height: 30px;
  width: 30px;
  flex-shrink: 0;

  cursor: pointer;

  margin-right: 30px;

  svg {
    max-width: 100%;
    max-height: 100%;
  }
}

.product__configNavUpdate {
  position: relative;
  height: 30px;
  width: 30px;
  flex-shrink: 0;

  cursor: pointer;

  svg {
    max-width: 100%;
    max-height: 100%;
  }
}

.product__show {
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
  transition: height 0.2s;
}

.product__showBelt {
  height: 225px;
}

.product__showBag {
  height: 350px;
}

.product__showBelt .product__showDragList {
  opacity: 1;
  visibility: visible;
}

.product__showBag .product__showDragList {
  top: auto;
  bottom: 21%;

  padding-right: 17%;
  padding-left: 17%;

  opacity: 1;
  visibility: visible;
}

.product__show__shrink {
  @media (max-width: $media-mobile-table-2) {
    height: 225px;
  }
  @media (max-width: $media-mobile-phone) {
    height: 100px;
  }
}

.product__showImgContainer {
  padding: 10px;

  flex-direction: column;
  overflow: hidden;
  min-height: 690px;

  @media (max-width: $media-mobile-table) {
    min-height: 600px;
    padding: 0px 63px;
  }

  @media (max-width: $media-mobile-table-2) {
    min-height: auto;
  }
}

.showImgMobile {
  position: absolute;
  top: 10px;
  left: 10px;

  width: 7.5%;
  min-width: 55px;
}

.showImgMobile:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.showImgMobileImg {
  max-height: 100%;
  max-width: 100%;
}

.product__showImgTrash {
  appearance: none;
  position: absolute;
  right: 30px;
  bottom: 15%;

  height: 105px;
  width: 105px;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  background-color: transparent;
  border: none;

  @media (max-width: $media-mobile-table) {
    right: 10px;
    bottom: 10px;

    box-sizing: content-box;
    padding: 8px;

    height: 35px;
    width: 35px;
  }
}

.product__showImgTrash_inactive {
  opacity: 0.5;
}

.product__showImgTrashIcon {
  height: auto;
  width: 35px;
}

.product__showImg {
  position: relative;
  max-height: 100%;
  max-width: 100%;
  user-select: none;
  z-index: -2;

  display: table;
  margin: 0 auto;

  @media (max-width: $media-mobile-phone) {
    max-height: none;
    max-width: 285px;
  }
}

.product__showImg__scrolled {
  @media (max-width: $media-mobile-table-2) {
    max-height: none;
    max-width: 210px;
  }
  @media (max-width: $media-mobile-phone) {
    max-height: none;
    max-width: 112px;
  }
}

.product__showImgHint {
  max-height: 100%;
  max-width: 100%;
}

.product__showImgContainerInner {
  position: relative;
  max-height: 100%;
}

.product__showImgBelt {
  position: absolute;
}

.product__showImgBelt_img {
  width: 100%;
  @media (max-width: $media-mobile-table) {
    max-height: 170px;
  }
  @media (max-width: $media-mobile-phone) {
    max-height: 85px;
  }
}

.product__showImgBlock {
  @media (max-width: $media-mobile-table) {
    height: initial;

    //display: flex;
    //justify-content: center;
  }
}

.product__showDragList {
  position: absolute;
  top: 35%;

  margin: 0;
  padding: 0;
  list-style: none;

  transition: 250ms;

  visibility: hidden;
  opacity: 0;

  width: 100%;

  display: flex;
  justify-content: space-between;

  @media (max-width: $media-mobile-phone) {
    transition: none;
  }
}

.product__showDragListInner {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: $media-mobile-phone) {
    width: 285px;
  }
}

.product__showDragListInner__hide__header {
  @media (max-width: $media-mobile-table-2) {
    width: 225px;
  }
  @media (max-width: $media-mobile-phone) {
    width: 112px;
  }
}

.product__showDragListInnerImg {
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;

  border: 1px dashed transparent;
  height: 200px;

  @media (max-width: $media-mobile-table) {
    height: 160px;
  }

  @media (max-width: "1150px") {
    height: 130px;
  }

  @media (max-width: "1024px") {
    height: 120px;
  }

  @media (max-width: $media-mobile-table-2) {
    height: 185px;
  }

  @media (max-width: "850px") {
    height: 165px;
  }

  @media (max-width: "800px") {
    height: 145px;
  }

  @media (max-width: "700px") {
    height: 130px;
  }

  @media (max-width: $media-mobile-phone) {
    height: 65px;
  }
}

.product__showDragItem {
  position: relative;
  width: 100px;
  flex-shrink: 0;

  border: none;

  //background-color: var(--tough-orange-03);
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 1;

  @media (max-width: $media-mobile-table) {
    //box-shadow: inset 0 0 0 1px var(--tough-orange);
  }
}

.product__showDragItem::before {
  content: "";
  display: block;
  padding-top: 130px;
}

.product__showDragItem_active {
  //background-color: var(--tough-green);
  background-color: var(--tough-orange-03);
}

.product__showDragItem_unVisible {
  opacity: 0;
}

.product__showDragItem_imageSet {
  background-color: transparent;
  border: none;
  box-shadow: none;
  opacity: 1;
}

.product__showDragItem_imageSet::before {
  background-color: transparent;
}

.product__tab {
  max-height: calc(100vh - 60px);
  overflow-y: auto;

  display: flex;
  flex-direction: column;

  flex-grow: 1;
  transition: height 0.5s;

  @media (max-width: $media-mobile-table-2) {
    max-height: calc(100vh - 470px);
  }

  @media (max-width: $media-mobile-phone) {
    max-height: calc(100vh - 306px);
  }
}

.product__tab__shrink {
  @media (max-width: $media-mobile-table-2) {
    max-height: calc(100vh - 250px);
  }

  @media (max-width: $media-mobile-phone) {
    max-height: calc(100vh - 120px);
  }
}

.product__tabColumn {
  position: relative;

  display: flex;
  flex-direction: column;

  max-width: 100%;

  flex-grow: 1;

  @media (max-width: $media-mobile-table-2) {
    overflow-x: hidden;
  }

  @media (max-width: $media-mobile-phone) {
    overflow-y: auto;
  }
}

.product__tabList {
  margin: 0;
  padding: 0;
  list-style: none;

  flex-grow: 1;

  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  padding-bottom: 20px;

  @media (min-width: $media-mobile-phone) and (max-width: $media-mobile-table-2) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 180px;
  }

  @media (max-width: $media-mobile-phone) {
    max-width: 100%;
    padding-bottom: 150px;
  }
}

.product__tabList__shrink {
  @media (max-width: $media-mobile-table-2) {
    padding-bottom: 175px;
  }
}

.product__tabList_isDrag {
  @media (max-width: $media-mobile-table) {
    overflow-x: hidden;
  }
}

.product__tabList__Loading {
  display: flex;
  justify-content: center;
  align-content: center;
}

.product__tabItem {
  position: relative;
  width: 50%;
  flex-shrink: 0;
  height: 253px;
  margin-bottom: -1px;
  margin-right: -1px;

  @media (min-width: $media-mobile-phone) and (max-width: $media-mobile-table-2) {
    width: auto;
  }

  @media (max-width: $media-mobile-phone) {
    height: 156px;
  }
}

.product__tabItem:hover {
  .product__tabZoom {
    display: inline-block;

    @media (max-width: $media-mobile-phone) {
      display: none;
    }
  }
}

.product__tabItemCount {
  position: absolute;
  top: 0;
  right: 0;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--tough-black);

  span {
    color: var(--tough-white);
    font-size: 0.75rem;
    line-height: 1.17;
    font-weight: 700;
  }
}

.product__tabItemActive {
  // TODO
}

.product__tabLink {
  appearance: none;
  background-color: transparent;

  position: relative;
  width: 100%;
  height: 100%;

  text-decoration: none;
  padding: 13px;

  color: var(--tough-black);
  cursor: pointer;
  border: 1px solid transparent;
}

.product__tabLinkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  width: 100%;
}

.product__tabLink_active {
  border: 1px solid var(--tough-black);

  .product__tabZoom {
    background-image: url("../../assets/images/icon/search-orange.svg");
  }
}

.product__tabLink_active__count {
  border: 1px solid var(--tough-black);
}

.product__tabLink_digit {
  background-color: var(--tough-orange-03);

  .product__tabZoom {
    background-image: url("../../assets/images/icon/search-orange.svg");
  }

  .product__tabDigit {
    display: inline-flex;
  }
}

.product__tabDigit {
  //display: inline-flex;
  align-items: center;
  justify-content: center;

  display: none;

  font-size: 0.875rem;
  color: var(--tough-white);
  font-weight: 700;

  content: "";
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  padding-right: 2px;
  padding-left: 2px;

  min-width: 31px;
  height: 31px;

  background-color: var(--tough-orange);
}

.product__tabDigitNumber {
  margin-right: 2px;
}

.product__tabDigitSymbol {
  height: 10px;
  width: 10px;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  background-image: url("../../assets/images/icon/plus.svg");
}

.product__tabLink_active::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  width: 31px;
  height: 31px;

  background-color: var(--tough-black);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center center;
  background-image: url("../../assets/images/icon/checked.svg");
}

.product__tabImg {
  position: relative;
  width: 100%;
  height: 120px;

  margin-top: 7px;
  margin-bottom: 16px;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  @media (max-width: $media-mobile-table) {
    margin-bottom: 10px;
  }
}

.product__tabCaption {
  font-size: 16px;
  font-weight: 900;
  line-height: 18px;
  height: 36px;
  text-align: center;
  text-transform: uppercase;
  overflow: hidden;

  margin-bottom: 1px;

  @media (max-width: $media-mobile-phone) {
    font-size: 14px;
    line-height: 16px;
    white-space: nowrap;
    max-width: 100%;
    height: 32px;

    margin-bottom: 0px;
  }
}

.product__tabPrice {
  font-size: 15px;

  @media (max-width: $media-mobile-table) {
    font-size: 0.875rem;
  }
}

.product__tabZoom {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;

  appearance: none;
  border: none;
  background-color: transparent;
  padding: 0;

  //display: inline-block;
  display: none;
  width: 24px;
  height: 24px;

  cursor: pointer;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  background-image: url("../../assets/images/icon/info-gray.svg");

  @media (max-width: $media-mobile-table) {
    top: 8px;
    left: 8px;

    height: 20px;
    width: 20px;

    background-size: 100% auto;
  }
}

.moveItem {
  position: absolute;
  z-index: 10;
  user-select: none;
  height: auto;
  object-fit: contain;
  transform: translate(-50%, -50%);
  opacity: 1;
}

.tooltip {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20;

  display: flex;
  flex-direction: column;

  &::before {
    content: "";
    flex: 1;
    display: block;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }

  &::after {
    content: "";
    flex: 1;
    display: block;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.tooltipInner {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;

  &::before {
    content: "";
    flex: 1;
    display: block;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }

  &::after {
    content: "";
    flex: 1;
    display: block;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.tooltipContent {
  flex: 1;
}

.product_removeItem {
  z-index: 10;
  position: absolute;
  height: 26px;
  width: 26px;
  top: 8px;
  right: 8px;
  cursor: pointer;

  @media (max-width: $media-mobile-phone) {
    height: 18.35px;
    width: 18.35px;
    top: 3px;
    right: 3px;
  }
}

.product_removeItemFixed {
  position: absolute;
  height: 26px;
  width: 26px;
  top: 8px;
  right: 8px;
  z-index: 10;
  cursor: pointer;

  @media (max-width: $media-mobile-phone) {
    height: 18.35px;
    width: 18.35px;
    top: 2.82px;
    right: 2.82px;
  }
}

.product_disableDrag {
  -webkit-user-drag: none;
}

.dwarfContentScroll {
  @media (max-width: $media-mobile-phone) {
    height: 96px;
    transition: 0.2s;
  }
}

.hideContentScrol {
  @media (max-width: $media-mobile-phone) {
    display: none;
    transition: 0.2s;
  }
}

.headMobile {
  @media (max-width: $media-mobile-phone) {
    height: 260px;
  }
}

.product_eye {
  position: absolute;
  top: -10px;
  right: -115px;
  display: block;
  opacity: 1;
  transition: opacity 0.5s;

  @media (min-width: $media-mobile-phone) and (max-width: $media-mobile-table-2) {
    top: -13px;
    right: -188px;
  }
}

.product_eye_hidden {
  transition: opacity 0.5s;
  display: none;
  opacity: 0;
}

.product__image__cell {
  width: 100%;
  @media (max-width: $media-mobile-phone) {
    max-width: 60px;
    max-height: 60px;
  }
}

.product__showDragListInnerImg__no__border__right {
  border-right-color: transparent;
}

.hint {
  position: absolute;
  bottom: 100px;
  left: 50%;
  z-index: 10;
  transform: translateX(-50%) translateY(60px);

  background-color: var(--tough-black);

  text-transform: uppercase;
  font-size: 0.75rem;
  color: var(--tough-white);

  padding-top: 8px;
  padding-right: 30px;
  padding-bottom: 8px;
  padding-left: 30px;

}
