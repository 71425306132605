@import "../../assets/sass/global/variable-media";

.innerPopupClasses {
  position: relative;

  //width: 20%;
  height: 540px;
  background-color: var(--tough-white);

  padding: 33px 23px 20px 30px;

  width: 100%;
  max-width: 892px;

  display: flex;
  flex-direction: row;

  @media (max-width: $media-mobile-table) {
    height: 620px;
    width: 580px;
    padding: 40px 54px;

    flex-direction: column;
  }

  @media (max-width: $media-mobile-phone) {
    flex-direction: column;
    height: 515px;

    max-width: unset;
    padding: 20px 16px;
    width: 90%;
  }
}

.information__column {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--tough-white);

  margin-bottom: 12px;

  @media (min-width: $media-mobile-phone) and (max-width: $media-mobile-table) {
    margin-bottom: 16px;
  }
}

.information__rowColumn {
  position: relative;
}

.information__rowColumn:first-child {
  width: 175px;
  flex-shrink: 0;

  margin-right: auto;

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  @media (max-width: $media-mobile-phone) {
    order: 1;
    overflow: hidden;

    width: 100%;
    height: 175px;
  }
}

.information__rowColumnImg {
  max-height: 100%;
  max-width: 100%;
}

.information__rowColumn:last-child {
  margin-left: 15px;

  @media (max-width: $media-mobile-phone) {
    margin-bottom: 30px;
  }
}

.information__code {
  color: var(--tough-black);
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 300;
}

.information__rate {
  font-weight: 200;
  font-size: 12px;
}

.information__rowColumnTitle {
  color: var(--tough-black);
  text-transform: uppercase;
  font-size: 46px;
  font-weight: 950;
  line-height: 39px;
  width: 100%;
  margin-bottom: 12px;

  @media (max-width: $media-mobile-table) {
    display: none;
  }
}

.information__block {
  max-height: 290px;
  overflow-y: auto;

  padding-right: 10px;

  @media (max-width: $media-mobile-phone) {
    max-height: calc(100% - 276px);
  }
}

.information__block::-webkit-scrollbar {
  width: 7px;
}

.information__block::-webkit-scrollbar-track {
  background: transparent;
}

.information__block::-webkit-scrollbar-thumb {
  background: var(--tough-gray-4);
  border-radius: 7px;
}

.information__desc {
  //overflow-y: auto;
  //max-height: 208px;

  line-height: 19px;
  font-size: 16px;
  font-weight: 300;

  text-align: left;

  margin-bottom: 30px;
  width: 100%;

  @media (max-width: $media-mobile-phone) {
    font-size: 14px;
    line-height: 20px;
  }
}

.information__descItem:not(:last-child) {
  margin-bottom: 30px;
}

.information__attributeList {
  margin: 0;
  padding: 0;
  list-style: none;
}

.information__attributeItem {
  display: flex;
  align-items: center;
}

.information__attributeItemColumn {
  align-items: flex-start;
}

.information__attributeName {
  width: 130px;
  flex-shrink: 0;

  text-align: right;

  text-transform: uppercase;
  font-weight: 700;

  margin-right: 20px;

  line-height: 1.44;
}

.information__attributeValue {
  text-transform: uppercase;

  line-height: 1.44;

  text-align: left;
}

.information__attributeValueItem {
  text-align: left;
}

.information__description__container {
  height: 300px;
  margin-bottom: 38px;
  overflow-y: scroll;

  position: relative;

  @media (max-width: $media-mobile-table) {
    height: 120px;
    margin-bottom: 46px;
    overflow-y: auto;
  }

  @media (max-width: $media-mobile-phone) {
    height: 200px;
    margin-bottom: 0px;
    overflow-y: auto;
  }
}

.information__description__container__overflow {
  overflow-y: auto;
}

.information__description__open_icon {
  height: 22px;
  width: 22px;

  position: absolute;
  bottom: 0px;
  left: 300px;

  @media (max-width: $media-mobile-phone) {
    left: auto;
    right: 10px;
    display: none;
  }
}

.information__description__info {
  width: 322px;

  @media (max-width: $media-mobile-table) {
    width: 100%;
  }
}

.information__code_stars {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 14px;
  margin-bottom: 20px;
  align-items: center;

  @media (max-width: $media-mobile-table) {
    display: none;
  }
}

.information__price_share {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 322px;
  margin-bottom: 23px;

  @media (max-width: $media-mobile-table) {
    display: none;
  }
}

.information__price {
  color: var(--tough-black);
  font-weight: 200;
  font-size: 32px;
  line-height: 38px;

  @media (max-width: $media-mobile-table) {
    font-weight: 300;
    font-size: 34px;
    line-height: 30px;
  }
}

.information__share {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  justify-content: flex-end;
}

.information__share__icon {
  border: 1px solid #b9b9b9;
  width: 50px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--tough-white);

  @media (max-width: $media-mobile-table) {
    height: 200px;
    margin-top: 0px;
  }

  @media (max-width: $media-mobile-phone) {
    height: 122px;
    margin-top: 28px;
  }
}

.carousel__selected__image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 375px;

  .image__selected {
    width: 90%;
  }

  @media (max-width: $media-mobile-table) {
    height: 200px;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;

    .image__selected {
      height: 90%;
      width: auto;
    }
  }

  @media (max-width: $media-mobile-phone) {
    height: 120px;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;

    .image__selected {
      height: 90%;
      width: auto;
    }
  }
}

.carousel__option_images {
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  justify-content: center;

  @media (max-width: $media-mobile-table) {
    display: none;
  }
}

.carousel__option {
  border: 1px solid var(--tough-soft-gray);
  height: 70px;
  width: 70px;
  padding: 5px;

  img {
    width: 100%;
    height: 100%;
  }
}
.carousel__option__selected {
  border: 1px solid var(--tough-black);
}

.information__ellipsis__text {
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
  height: max-content;
}

.information__name_mobile {
  display: none;
  @media (max-width: $media-mobile-table) {
    display: block;
    font-weight: 900;
    font-size: 28px;
    line-height: 28px;
    height: 84px;
    min-height: 84px;
    text-transform: uppercase;
    margin: 0px;
    margin-bottom: 30px;
  }
  @media (max-width: $media-mobile-phone) {
    display: block;
    font-weight: 900;
    font-size: 28px;
    line-height: 28px;
    height: 56px;
    min-height: 56px;
    text-transform: uppercase;
    margin: 0px;
    padding-right: 25px;
  }
}

.information__price__button {
  width: 100%;

  span {
    display: none;
  }

  @media (max-width: $media-mobile-table) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    span {
      display: block;
    }
  }

  @media (max-width: $media-mobile-phone) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;

    span {
      display: block;
    }
  }
}

.information__transparent {
  display: block;
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;

  background: linear-gradient(
    185.62deg,
    rgba(255, 255, 255, 0) 4.47%,
    #ffffff 75.34%
  );
}

.carousel__arrow__control {
  display: none;

  @media (max-width: $media-mobile-table) {
    display: block;

    height: 18px;
    width: 10px;
  }
}
