@import "../../assets/sass/global/variable-media";

.product__nav {
  margin: 0;
  margin-bottom: -1px;
  padding: 0;
  list-style: none;
  height: 53px;

  display: flex;

  @media (max-width: $media-mobile-phone) {
    height: 44px;
  }
}
