@import "../../../assets/sass/global/variable-media";

.container {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.caption {
  margin-top: 0;
  margin-bottom: 75px;

  text-transform: uppercase;

  font-size: 1.875rem;

  display: flex;

  strong {
    margin-left: 5px;
  }

  @media (max-width: $media-mobile-phone) {
    font-size: 1.5rem;
    margin-bottom: 55px;
  }
}

.img {
  position: relative;
  left: -45px;

  width: 9.47%;
  flex-shrink: 0;

  min-width: 182px;

  &::before {
    content: "";
    display: block;
    padding-top: 159.3%;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }

  @media (max-width: $media-mobile-phone) {
    width: 10.15%;

    min-width: 78px;

    &::before {
      padding-top: 160.2%;
    }
  }
}

.img__1 {
  position: absolute;
  top: 16px;
  left: 80px;
  z-index: 1;

  width: 91.2%;
  flex-shrink: 0;

  opacity: 0;

  min-width: 166px;

  animation-name: animate-img;
  animation-duration: 2s;
  animation-fill-mode: forwards;

  &::before {
    content: "";
    display: block;
    padding-top: 154.7%;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }

  @media (max-width: $media-mobile-phone) {
    width: 9.24%;
    top: 10px;
    left: 40px;

    min-width: 71px;

    &::before {
      padding-top: 154.9%;
    }
  }
}

.img__2 {
  position: absolute;
  top: 16px;
  left: 176px;
  z-index: 1;

  width: 96.7%;
  flex-shrink: 0;

  opacity: 0;

  min-width: 176px;

  animation-name: animate-img;
  animation-delay: 1.5s;
  animation-duration: 2s;
  animation-fill-mode: forwards;

  &::before {
    content: "";
    display: block;
    padding-top: 146.5%;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }

  @media (max-width: $media-mobile-phone) {
    width: 9.75%;
    top: 10px;
    left: 81px;

    min-width: 75px;

    &::before {
      padding-top: 146.6%;
    }
  }
}

@keyframes animate-img {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

