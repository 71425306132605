:root {
  --tough-white: rgb(255, 255, 255);
  --tough-white-0: rgba(255, 255, 255, 0);
  --tough-black: rgb(0, 0, 0);
  --tough-gray: rgb(204, 204, 204);
  --tough-gray-2: rgb(196, 196, 196);
  --tough-gray-3: rgb(91, 91, 91);
  --tough-gray-4: rgb(110, 113, 110);
  --tough-gray-5: rgb(178, 178, 178);
  --tough-gray-6: rgb(155, 155, 155);
  --tough-gray-7: rgba(179, 179, 179, 1);
  --tough-gray-8: rgba(185, 185, 185, 1);
  --tough-gray-8-016: rgba(185, 185, 185, 0.16);
  --tough-gray-9: rgba(193, 193, 193, 0.3);
  --tough-orange: rgb(248, 161, 50);
  --tough-orange-1: rgba(248, 161, 50, 0.1);
  --tough-orange-03: rgba(248, 161, 50, 0.3);
  --tough-brown: rgb(219, 175, 118);
  --tough-brown-2: rgb(157, 108, 32);
  --tough-brown-3: rgba(124, 87, 10, 1);
  --tough-green: rgba(40, 167, 69, 0.3);
  --tough-yellow: rgba(247, 175, 21);
  --tough-soft-gray: rgba(212, 211, 211, 1);
  --tough-dark-gray: rgba(102, 102, 102, 1);
}
