@font-face {
  font-family: 'HelveticaNeueCondensed';
  src: url('../../font/helvetica-neue/HelveticaNeueCondensed-CondensedBlack.woff2') format('woff2'),
  url('../../font/helvetica-neue/HelveticaNeueCondensed-CondensedBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueCondensed';
  src: url('../../font/helvetica-neue/HelveticaNeueCondensed-CondensedBold.woff2') format('woff2'),
  url('../../font/helvetica-neue/HelveticaNeueCondensed-CondensedBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueCondensed';
  src: url('../../font/helvetica-neue/HelveticaNeueThinCond-CondensedRegularThin.woff2') format('woff2'),
  url('../../font/helvetica-neue/HelveticaNeueThinCond-CondensedRegularThin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueCondensed';
  src: url('../../font/helvetica-neue/HelveticaNeueCondensed-CondensedRegular.woff2') format('woff2'),
  url('../../font/helvetica-neue/HelveticaNeueCondensed-CondensedRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

