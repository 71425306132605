@import "./variable-media";
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: "HelveticaNeueCondensed", sans-serif, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overscroll-behavior-y: none;
  overscroll-behavior-x: none;

  min-width: 320px;
  position: fixed;
  overflow: hidden;

  ::-webkit-scrollbar {
    width: 3.5px;
    height: 3.5px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #D9D9D9;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #888; 
  }
}

.wrapper-outer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  //min-height: 100vh;

  display: flex;
  flex-direction: column;

  @media (max-width: $media-mobile-phone) {
    overflow: unset;
  }
}

.wrapper {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;

  display: flex;
  flex-direction: column;
}

.container {
  width: 89.5%;
  //max-width: 1260px;

  margin: 0 auto;

  padding-right: 10px;
  padding-left: 10px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

.visually__hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;

  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.img__container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.btn__close {
  appearance: none;
  border: none;

  position: absolute;
  top: 22px;
  right: 21px;
  z-index: 1;

  display: inline-flex;

  height: 22px;
  width: 22px;

  box-sizing: content-box;
  padding: 0px;

  cursor: pointer;

  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto auto;
  background-clip: padding-box;
  background-image: url("../../images/icon/close.svg");

  @media (max-width: $media-mobile-phone) {
    top: 20px;
    right: 20px;

    height: 20px;
    width: 20px;
  }
}

@media (max-width: 1024px) {
  .container {
    width: 100%;

    padding-right: 18px;
    padding-left: 18px;
  }
}
