.loader {
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  cursor: auto;
}

.dropdown {
  width: 24px;
  height: 24px;
  border: 4px solid var(--tough-dark-gray);
  border-bottom-color: transparent;
}

.products {
  width: 40px;
  height: 40px;
  border: 4px solid var(--tough-black);
  border-bottom-color: transparent;
}

.button {
  width: 16px;
  height: 16px;
  border: 2px solid var(--tough-black);
  border-bottom-color: transparent;
}

.headerButton {
  width: 12px;
  height: 12px;
  border: 2px solid var(--tough-black);
  border-bottom-color: transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
