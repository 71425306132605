@import "../../assets/sass/global/variable-media";

.product__tabBuy {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 3;
  width: 382px;

  overflow: hidden;

  transition: 250ms;

  background-color: var(--tough-white);

  display: flex;
  align-items: center;
  justify-content: space-between;
  //border-top: 1px solid var(--tough-gray);

  @media (max-width: $media-mobile-table-2) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.product__tabBuy_active {
  height: 50px;
  z-index: 8;

  .product__tabBuyPriceBtn {
    opacity: 1;
  }

  .product__tabBuyPriceNumber {
    opacity: 1;
  }

  .product__tabBuyBtn {
    opacity: 1;
  }

  @media (max-width: $media-mobile-phone) {
    height: 44px;
  }
}

.product__tapBuyPrice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  padding-left: 10px;
  padding-right: 6px;
  border-top: 1px solid #b9b9b9;
  height: 100%;

  @media (max-width: $media-mobile-phone) {
    padding-left: 21px;
    padding-right: 16px;
  }
}

.product__tabBuyPriceBtn {
  appearance: none;
  padding: 0;
  border: none;
  border-radius: 50%;
  opacity: 0.5;

  cursor: pointer;

  height: 34px;
  width: 34px;
  flex-shrink: 0;

  margin-right: 15px;

  background-color: var(--tough-black);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  background-image: url("../../assets/images/icon/arrow-top.svg");

  transition: 0.2s;
}

.product__tabBuyPriceBtn_down {
  transform: scale(-1);
}

.product__tabBuyPriceNumber {
  font-size: 32px;
  line-height: 38px;
  font-weight: 200;

  @media (max-width: $media-mobile-phone) {
    font-size: 26px;
    line-height: 30px;
  }
}

.product__tabBuyBtn {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 50%;
  height: 100%;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--tough-black);
  opacity: 0.5;

  background-color: var(--tough-yellow);
  border: 1px solid var(--tough-brown-3);
  cursor: pointer;

  @media (max-width: $media-mobile-phone) {
    min-width: unset;
    flex-grow: 1;
  }
}

.product__quantity {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;

  background-color: var(--tough-white);

  overflow: hidden;
  height: 0;
  max-height: 0;

  transition: 350ms linear;

  display: flex;
  flex-direction: column;

  @media (max-width: $media-mobile-table-2) {
    position: fixed;
    z-index: 7;
    padding-right: 20px;
    padding-left: 20px;
  }

  @media (max-width: $media-mobile-phone) {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.product__quantity_active {
  height: 100%;
  max-height: 100%;
}

.product__quantityHeader {
  padding: 21px 30px 19px 17px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid var(--tough-gray);

  @media (max-width: $media-mobile-phone) {
    padding: 24px 26px 25px 16px;
  }
}

.product__quantityHeaderBtn {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.product__quantityHeaderCaption {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;

  @media (max-width: $media-mobile-phone) {
    line-height: 22px;
  }
}

.product__quantityList {
  margin: 0;
  padding: 0;
  padding-bottom: 35px;
  list-style: none;

  max-height: calc(100% - 100px);
  overflow-y: auto;
}

.product__quantityListInner {
  margin: 0;
  padding: 0;
  list-style: none;

  & > li {
    padding-right: 13px;
    padding-left: 16px;

    background-color: var(--tough-gray-8-016);

    border-bottom: none;

    @media (max-width: $media-mobile-table-2) {
      padding-right: 22px;
      padding-left: 20px;
    }

    @media (max-width: $media-mobile-phone) {
      padding-right: 29px;
      padding-left: 15px;
    }
  }
}

.product__quantityItem {
  display: flex;

  padding-top: 10px;
  padding-bottom: 14px;

  border-bottom: 1px solid var(--tough-gray);
  height: 80px;

  @media (max-width: $media-mobile-phone) {
    height: 90px;
    padding-bottom: 13px;
  }
}

.product__quantityItem__header {
  padding: 0px;
  padding-left: 13px;

  @media (max-width: $media-mobile-phone) {
    height: 96px;
    padding-left: 0px;
  }
}

.product__quantity__card__data__image__container__item {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 61px;
  height: 64px;

  margin-right: 15px;

  img {
    height: 100%;
    width: 100%;
  }

  @media (max-width: $media-mobile-phone) {
    height: 64px;
    width: 67px;
    margin-right: 11px;
  }
}

.product__quantityInfo {
  display: flex;
  align-self: stretch;

  flex-direction: column;
  align-items: flex-start;

  //margin-right: 5px;
}

.product__quantityInfo__code {
  text-transform: uppercase;
  font-weight: 200;
  font-size: 12px;
  line-height: 16px;

  margin-bottom: 2px;

  text-align: left;
}

.product__quantityInfo__name {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;

  margin-bottom: 3px;

  text-align: left;
}

.product__quantityInfo__price {
  margin-top: auto;
  font-weight: 700;
  text-align: left;
  font-size: 15px;
  line-height: 18px;
}

.product__quantityConfig {
  display: flex;
  align-self: stretch;

  flex-direction: column;
  align-items: flex-end;

  margin-left: auto;
}

.product__quantityConfigChange {
  position: relative;
  bottom: -5px;

  display: flex;
  align-items: center;

  margin-top: auto;
}

.product__quantityConfigChange__btn {
  appearance: none;
  border: none;

  display: inline-flex;

  height: 20px;
  width: 9px;

  box-sizing: content-box;
  padding: 5px;

  cursor: pointer;

  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 9px auto;
  background-clip: padding-box;
}

.product__quantityConfigChange__btnPrev {
  background-image: url("../../assets/images/icon/left.svg");
}

.product__quantityConfigChange__btnNext {
  background-image: url("../../assets/images/icon/right.svg");
}

.product__quantityConfigChange__value {
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;

  text-align: center;

  margin-right: 4px;
  margin-left: 4px;

  min-width: 30px;
  flex-shrink: 0;
}

.product__quantityDropdown {
  position: relative;

  appearance: none;

  border: none;
  background-color: transparent;
  padding: 0;

  cursor: pointer;

  display: inline-flex;

  margin-right: 13px;

  align-self: center;

  transition: 250ms;

  width: 20px;
  height: 20px;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.product__quantityDropdownActive {
  transform: scale(-1);
}

.product__quantity__card__data__image__container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 80px;
  height: 100%;

  margin-right: 12px;

  img {
    height: 75px;
    width: 75px;
  }

  @media (max-width: $media-mobile-phone) {
    height: 86px;
    width: 86px;
    margin-right: 10px;

    img {
      height: 80px;
      width: 80px;
    }
  }
}

.product__quantityInfo__header {
  padding: 13px 29px 13px 0px;
  flex: 1;

  @media (max-width: $media-mobile-phone) {
    padding: 15px 0px 18px 0px;
    row-gap: 12px;
  }
}

.product__quantityInfo__row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.product__quantityInfo__code__principal {
  font-weight: 900;
  font-size: 16px;
  line-height: 18px;
}

.btn__navContainer {
  display: inline-flex;
  align-items: center;
}

.btn__navEdit {
  appearance: none;
  border: none;

  cursor: pointer;

  background-color: transparent;

  margin-bottom: 5px;

  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  color: var(--tough-dark-gray);
  text-transform: uppercase;
  text-decoration: underline;

  display: inline-flex;
}

.product__quantityInfo__price__principal {
  font-weight: 400;
  margin-top: 0px;
  font-size: 20px;
  line-height: 24px;
}

.product__quantityInfo__edit__remove {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 23px;
  padding-right: 5.5px;

  @media (max-width: $media-mobile-table-2) {
    justify-content: flex-end;
    column-gap: 38px;
  }
}

.product__quantityInfo__edit__count {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.product__quantityConfig__btn__header {
  height: 23px;
  width: 17px;
  margin-right: 6px;
  cursor: pointer;
}
