@import "../../assets/sass/global/variable-media";

.innerPopupClasses {
  position: relative;

  height: 248px;
  background-color: var(--tough-white);

  padding: 60px;

  width: 100%;
  max-width: 515px;

  margin-right: 16px;
  margin-left: 16px;

  @media (max-width: $media-mobile-phone) {
    padding-top: 60px;
    padding-right: 20px;
    padding-bottom: 40px;
    padding-left: 20px;
    height: 334px;
  }
}

.desc {
  margin-bottom: 60px;
  line-height: 28px;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;

  @media (max-width: $media-mobile-phone) {
    text-align: center;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    width: 226px;
  }
}

.desContainer {
  @media (max-width: $media-mobile-phone) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.accept {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  width: 100%;
  justify-content: center;
  @media (max-width: $media-mobile-phone) {
    flex-direction: column;
  }
}

.acceptBtn {
  appearance: none;

  display: flex;
  justify-content: center;
  align-items: center;

  text-transform: uppercase;

  background-color: transparent;
  border: solid 1px var(--tough-brown-3);

  height: 40px;
  width: 170px;

  font-weight: 200;
  color: var(--tough-black);

  cursor: pointer;

  @media (max-width: $media-mobile-phone) {
    width: 314px;
    height: 44px;
  }
}

.acceptBtn:first-child {
  margin-bottom: 10px;
}

.acceptBtnEnter {
  border: 1px solid var(--tough-gray-7);
  font-size: 16px;
  line-height: 18px;
}

.acceptBtnCancel {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  border: 1px solid var(--tough-brown-3);
  background-color: var(--tough-yellow);

  @media (max-width: $media-mobile-phone) {
    line-height: 18px;
  }
}
