@import "../../assets/sass/global/variable-media";

.menuContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media (max-width: $media-mobile-phone) {
    height: 58px;
  }
}

.mediumRow {
  display: flex;
  flex-direction: row;
  column-gap: 18px;
  align-items: center;

  @media (max-width: $media-mobile-phone) {
    column-gap: 0px;
  }
}

.buttonsRow {
  column-gap: 10px;
}

.innerPopupClasses {
  position: relative;
  max-width: 515px;
  width: 100%;
  height: 296px;
  padding-top: 60px;
  background-color: var(--tough-white);
  
  
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 16px;
  margin-left: 16px;

  @media (max-width: $media-mobile-phone) {
    height: 343px;
  }
}

.titlePopup {
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  width: 276px;
  margin-bottom: 32px;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: $media-mobile-phone) {
    width: 226px;
    margin-bottom: 60px;
  }
}

.buttonsRowPopup {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;

  @media (max-width: $media-mobile-phone) {
    flex-direction: column;
    padding-left: 13px;
    padding-right: 13px;
    row-gap: 10px;
    align-items: center;
  }
}

.discardButton {
  width: 170px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--tough-gray-7);

  font-weight: 200;
  font-size: 16px;
  line-height: 18px;

  @media (max-width: $media-mobile-phone) {
    width: 100%;
  }
}

.cancelLink {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;

  text-decoration-line: underline;
  text-transform: uppercase;
  color: var(--tough-dark-gray);
}
