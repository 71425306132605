@import "../../../assets/sass/global/variable-media";

.container {
  width: 100%;

  padding-right: 20px;
  padding-left: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.caption {
  position: relative;

  width: 100%;

  margin-top: 0;
  margin-bottom: 75px;

  text-transform: uppercase;

  font-size: 1.875rem;

  display: flex;

  strong {
    margin-right: 5px;
  }

  @media (max-width: $media-mobile-phone) {
    font-size: 1.5rem;
    margin-bottom: 70px;
  }
}

.caption__item {
  position: absolute;
  overflow: hidden;

  left: 50%;
  transform: translateX(-50%);

  font-size: inherit;
  text-transform: inherit;

  //visibility: hidden;
  white-space: nowrap;
  opacity: 0;

  animation-name: animate-text;
  animation-duration: 5s;
}

.caption__item1 {
  //animation-delay: 500ms;
}

.caption__item2 {
  animation-delay: 1.2s;
}

.caption__item3 {
  animation-delay: 1.5s;
  animation-name: animate-text-2;
  animation-fill-mode: forwards;
}

@keyframes animate-text {
  0%, 20% {
    opacity: 1;
  }
  20.01%, 100% {
    opacity: 0;
  }
}

@keyframes animate-text-2 {
  0%, 20% {
    opacity: 0;
  }
  20.01%, 100% {
    opacity: 1;
  }
}

//@keyframes hide-text {
//  0% {
//    opacity: 1;
//  }
//  100% {
//    opacity: 0;
//    visibility: hidden;
//
//    width: 0;
//    height: 0;
//  }
//}

.containerInner {
  width: 24.68%;
  flex-shrink: 0;

  min-width: 475px;

  @media (max-width: $media-mobile-phone) {
    min-width: 500px;
  }

  @media (max-width: $media-mobile-phone) {
    min-width: 350px;
  }

  @media (max-width: $media-mobile-phone) {
    min-width: 210px;
  }
}

.img {
  position: relative;

  width: 86.3%;
  margin: 0 auto;
  flex-shrink: 0;

  margin-bottom: 10px;

  &::before {
    content: "";
    display: block;
    padding-top: 66.8%;
  }

  img {
    max-height: 100%;
    width: 100%;
  }
}

.img__1 {
  position: absolute;
  right: 10.9%;
  bottom: 40px;
  z-index: 1;

  width: 38.2%;
  flex-shrink: 0;

  //min-width: 157px;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
  background-image: url("../../../assets/images/animation/belt/pouches_1.png");

  animation-name: change-img;
  animation-delay: 2s;
  animation-duration: 1.2s;
  animation-fill-mode: forwards;

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }

  @media (max-width: $media-mobile-phone) {
    bottom: 20px;

    width: 100px;
  }

  @media (max-width: $media-mobile-phone) {
    width: 71px;

    right: 8%;
    bottom: 10px;
  }
}

@keyframes change-img {
  0% {

  }
  100% {
    background-image: url("../../../assets/images/animation/belt/pouches_1-white.svg");
  }
}

.hint {
  position: absolute;
  left: 17.5%;
  bottom: 40px;
  z-index: 1;

  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;

  opacity: 0;
  visibility: hidden;

  animation-name: show-img, hide-img;
  animation-delay: 750ms, 2s;
  animation-duration: 1.2s, 1.2s;
  animation-fill-mode: forwards, forwards;

  @media (max-width: $media-mobile-phone) {
    top: auto;
    bottom: 20px;
  }

  @media (max-width: $media-mobile-phone) {
    bottom: 10px;
    left: 15%;
  }
}

.hintItem {
  position: relative;
  width: 100px;
  flex-shrink: 0;

  border: 1px dashed var(--tough-orange);
  background-color: var(--tough-orange-03);

  opacity: 1;

  @media (max-width: $media-mobile-phone) {
    height: 75px;
    width: 65px;
  }
}

.hintItem::before {
  content: "";
  display: block;
  padding-top: 130%;
}

.hintItem:not(:last-child) {
  margin-right: 16px;

  @media (max-width: $media-mobile-phone) {
    margin-right: 0;
  }
}

.hintItem:nth-child(2) {
  @media (max-width: $media-mobile-phone) {
    display: none;
  }
}

.hintItem:nth-child(3) {
  margin-left: auto;

  @media (max-width: $media-mobile-phone) {
    display: none;
  }
}

.hintItem:nth-child(4) {
  @media (max-width: $media-mobile-phone) {
    margin-left: auto;
  }
}

.hintProduct {
  position: absolute;
  right: -110px;
  bottom: -5px;
  width: 27.31%;
  flex-shrink: 0;

  min-width: 75px;

  opacity: 0;

  animation-name: show-img;
  animation-delay: 2.2s;
  animation-duration: 1.2s;
  animation-fill-mode: forwards;

  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center center;
  background-image: url("../../../assets/images/animation/belt/pouches_1.png");

  @media (max-width: $media-mobile-table-2) {
    //right: 35px;
    right: -95px;
    //bottom: -50px;
  }

  @media (max-width: $media-mobile-phone) {
    right: -70px;
    bottom: -30px;
  }
}

.hintProduct::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.hintTrash {
  position: absolute;
  right: -195px;
  bottom: -45px;
  width: 54px;

  opacity: 0;

  animation-name: show-img;
  animation-delay: 2.2s;
  animation-duration: 1.2s;
  animation-fill-mode: forwards;

  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center center;
  background-image: url("../../../assets/images/icon/trash.svg");

  @media (max-width: $media-mobile-table-2) {
    right: -115px;
    bottom: -75px;
    //bottom: -115px;
  }

  @media (max-width: $media-mobile-phone) {
    width: 28px;

    right: -75px;
    bottom: -75px;
  }
}

.hintTrash:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.cursor {
  display: flex;
  justify-content: center;

  margin-bottom: -10px;

  animation-name: hide-img;
  animation-delay: 500ms;
  animation-duration: 1.2s;
  animation-fill-mode: forwards;
}

.cursorHint {
  position: relative;
  width: 5.8%;
  min-width: 44px;

  flex-shrink: 0;
}

.cursorHint::before {
  content: "";
  display: block;
  padding-top: 81.81%;
}

.cursorHintImg {
  max-height: 100%;
  max-width: 100%;
}

.kind {
  display: flex;
  justify-content: center;

  animation-name: hide-img;
  animation-delay: 500ms;
  animation-duration: 1.2s;
  animation-fill-mode: forwards;
}

.kind__list {
  margin: 0;
  padding: 0;
  list-style: none;

  width: 100%;

  display: flex;
  justify-content: center;
}

.kind__listItem {
  position: relative;

  width: 18.5%;
  flex-shrink: 0;

  min-width: 62px;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.kind__listItem::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.kind__listItem:not(:last-child) {
  margin-right: 40px;

  @media (max-width: $media-mobile-phone) {
    margin-right: 20px;
  }
}

@keyframes hide-img  {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes show-img  {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
