.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.5);
}

.closeButton {
  appearance: none;
  border: none;

  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;

  display: inline-flex;

  height: 18px;
  width: 18px;

  box-sizing: content-box;
  padding: 5px;

  cursor: pointer;

  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto auto;
  background-clip: padding-box;
  background-image: url("../../assets/images/icon/close.svg");
}

.popup {
  position: relative;

  width: 100%;
  max-width: 343px;

  background-color: var(--tough-white);

  padding-top: 55px;
  padding-right: 20px;
  padding-bottom: 40px;
  padding-left: 20px;

  margin-right: 16px;
  margin-left: 16px;
}

.popupTitleWrapper {

}

.popupTitle {
  font-size: 1rem;
  line-height: 1.44;

  font-family: 'HelveticaNeueCondensed', Arial, SansSerif;

  margin-bottom: 25px;
}

.popupButtonsWrapper {

}

.accept,
.confirm {
  appearance: none;
  display: flex;
  justify-content: center;

  text-align: center;

  padding-top: 12px;
  padding-right: 5px;
  padding-bottom: 12px;
  padding-left: 5px;

  color: var(--tough-black);

  width: 100%;
}

.accept {
  margin-bottom: 10px;

  background-color: var(--tough-white);

  border: solid 1px #b2b2b2;
}

.confirm {
  border: solid 1px #9d6c20;
  background-color: #f8a132;
}
