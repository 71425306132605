@import "../../../../assets/sass/global/variable-media.scss";

.container {
  border: 1px solid #b9b9b9;
  height: 28px;
  width: 91px;
  background: none;

  font-size: 11px;
  font-weight: 200;
  color: #000000;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
  cursor: pointer; 

  @media (max-width: $media-mobile-table-2) {
    font-size: 0px;
    border: none;
    width: 40px;
    height: 40px;
    column-gap: 0px;

    img {
      height: 24px;
      width: 24px;
    }
  }
}

.editingButton {
  background: #f7af15;
  border: 1px solid #7c570a;
}
