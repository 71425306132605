@import "../../../assets/sass/global/variable-media";

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.75);

  @media (max-width: $media-mobile-table) {
    z-index: 9;
  }
}

.wrapper__wrapperLikeButton {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
