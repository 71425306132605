@import "../../assets/sass/global/variable-media";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--tough-white);
  color: var(--tough-black);
  text-transform: uppercase;
  height: 100vh;
  padding-top: 100px;
  width: 100vw;

  @media (max-width: $media-mobile-phone) {
    padding-top: 66px;
  }
}

.title {
  font-weight: 950;
  font-size: 38px;
  height: 48px;
  margin-bottom: 32px;
  display: table-cell;
  vertical-align: top;

  @media (max-width: $media-mobile-phone) {
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 15px;
    font-weight: 900;
  }
}

.description {
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  margin-bottom: 70px;

  @media (max-width: $media-mobile-phone) {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 22px;
  }
}

.productList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
  row-gap: 16px;

  @media (max-width: $media-mobile-phone) {
    flex-wrap: wrap;
  }
}

.category {
  height: 200px;
  width: 200px;
  position: relative;

  display: flex;
  flex-direction: column;
  padding: 14px 26px 24px 26px;
  align-items: center;
  justify-content: space-evenly;

  font-weight: 900;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;

  @media (max-width: $media-mobile-phone) {
    height: 150px;
    width: 150px;

    font-size: 16px;
    line-height: 20px;
    padding-bottom: 10px;
  }
}

.category:hover {
  border: 1px solid var(--tough-black);

  .check {
    display: flex;
  }
}

.check {
  display: none;
  background-color: var(--tough-black);
  position: absolute;
  top: 0;
  right: 0;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;

  @media (max-width: $media-mobile-phone) {
    background-color: var(--tough-black);
    height: 24px;
    width: 24px;
  }
}

.image {
  height: 130px;

  @media (max-width: $media-mobile-phone) {
    height: 110px;
  }
}

.imageContainer {
  height: 130px;
  margin-bottom: 5px;

  @media (max-width: $media-mobile-phone) {
    height: 110px;
  }
}
