@import "../../../assets/sass/global/variable-media";

.container {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.caption {
  margin-top: 0;
  margin-bottom: 75px;

  text-transform: uppercase;

  font-size: 1.875rem;

  display: flex;

  strong {
    margin-left: 5px;
  }

  @media (max-width: $media-mobile-phone) {
    font-size: 1.5rem;
    margin-bottom: 50px;
  }
}

.img {
  position: relative;

  width: 21.35%;
  flex-shrink: 0;

  min-width: 410px;

  &::before {
    content: "";
    display: block;
    padding-top: 66.5%;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }

  @media (max-width: $media-mobile-phone) {
    width: 23.43%;

    min-width: 180px;

    &::before {
      padding-top: 67.23%;
    }
  }
}

.img__1 {
  position: absolute;
  right: 45px;
  bottom: 40px;
  z-index: 1;

  width: 20.9%;
  flex-shrink: 0;

  opacity: 0;

  min-width: 157px;

  animation-name: animate-img;
  animation-duration: 2s;
  animation-fill-mode: forwards;

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }

  @media (max-width: $media-mobile-phone) {
    width: 40.5%;
    top: 35px;
    right: 20px;
    bottom: auto;

    min-width: 73px;

    &::before {
      padding-top: 100%;
    }
  }
}

.img__2 {
  position: absolute;
  bottom: 18px;
  left: 58px;
  z-index: 1;

  width: 32.92%;
  flex-shrink: 0;

  opacity: 0;

  min-width: 137px;

  animation-name: animate-img;
  animation-delay: 1.5s;
  animation-duration: 2s;
  animation-fill-mode: forwards;

  &::before {
    content: "";
    display: block;
    padding-top: 140%;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }

  @media (max-width: $media-mobile-phone) {
    width: 33.3%;
    bottom: 10px;
    left: 23px;

    min-width: 60px;

    &::before {
      padding-top: 140%;
    }
  }
}

@keyframes animate-img {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

