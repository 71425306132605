@import "../../../assets/sass/global/variable-media";

.container {
  width: 100%;

  padding-right: 20px;
  padding-left: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.caption {
  position: relative;

  width: 100%;

  margin-top: 0;
  margin-bottom: 75px;

  text-transform: uppercase;

  font-size: 1.875rem;

  display: flex;

  strong {
    margin-right: 5px;
  }

  @media (max-width: $media-mobile-phone) {
    font-size: 1.5rem;
    margin-bottom: 70px;
  }
}

.caption__item {
  position: absolute;
  overflow: hidden;

  left: 50%;
  transform: translateX(-50%);

  font-size: inherit;
  text-transform: inherit;

  //visibility: hidden;
  white-space: nowrap;
  opacity: 0;

  animation-name: animate-text;
  animation-duration: 5s;
}

.caption__item1 {
  //animation-delay: 500ms;
}

.caption__item2 {
  animation-delay: 350ms;
  animation-name: animate-text-2;
  animation-fill-mode: forwards;
}

.caption__item3 {
  animation-delay: 1.5s;
  animation-name: animate-text-2;
  animation-fill-mode: forwards;
}

@keyframes animate-text {
  0%, 20% {
    opacity: 1;
  }
  20.01%, 100% {
    opacity: 0;
  }
}

@keyframes animate-text-2 {
  0%, 20% {
    opacity: 0;
  }
  20.01%, 100% {
    opacity: 1;
  }
}

//@keyframes hide-text {
//  0% {
//    opacity: 1;
//  }
//  100% {
//    opacity: 0;
//    visibility: hidden;
//
//    width: 0;
//    height: 0;
//  }
//}

.containerInner {
  width: 39.1%;
  flex-shrink: 0;

  min-width: 748px;

  @media (max-width: $media-mobile-phone) {
    min-width: 210px;
  }
}

.img {
  position: relative;

  width: 169px;

  margin: 0 auto;
  margin-bottom: 25px;

  &::before {
    content: "";
    display: block;
    padding-top: 159.7%;
  }

  img {
    max-height: 100%;
    width: 100%;
  }

  @media (max-width: $media-mobile-phone) {
    width: 78px;
  }
}

.img__1 {
  position: absolute;
  top: 15px;
  left: 72px;
  z-index: 1;

  width: 154px;
  flex-shrink: 0;

  //min-width: 157px;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
  background-image: url("../../../assets/images/animationDrag/pad/snapshells.svg");

  animation-name: change-img;
  animation-delay: 500ms;
  animation-duration: 1.2s;
  animation-fill-mode: forwards;

  &::before {
    content: "";
    display: block;
    padding-top: 155.19%;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }

  @media (max-width: $media-mobile-phone) {
    width: 71px;

    top: 8px;
    left: 41px;
  }
}

@keyframes change-img-position {
  0% {

  }
  100% {
    left: 32%;
  }
}

//@media (max-width: $media-mobile-phone) {
//  @keyframes change-img-position {
//    0% {
//
//    }
//    100% {
//      left: 27%;
//    }
//  }
//}

@keyframes change-img {
  0% {

  }
  100% {
    //left: 35%;
    background-image: url("../../../assets/images/animationDrag/pad/snapshells-white.svg");
  }
}

.hint {
  position: absolute;
  top: 32px;
  right: 8px;
  left: 8px;

  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;

  opacity: 0;
  visibility: hidden;

  animation-name: show-img, hide-img;
  animation-delay: 750ms, 2s;
  animation-duration: 1.2s, 1.2s;
  animation-fill-mode: forwards, forwards;
}

.hintItem {
  position: relative;
  //width: 100px;
  width: 13.66%;

  flex-shrink: 0;

  border: 1px dashed var(--tough-orange);
  background-color: var(--tough-orange-03);

  opacity: 1;
}

.hintItem::before {
  content: "";
  display: block;
  padding-top: 130%;
}

.hintProduct {
  position: absolute;
  top: 70%;
  right: -200px;
  width: 71px;
  flex-shrink: 0;

  opacity: 0;

  animation-name: show-img;
  animation-delay: 500ms;
  animation-duration: 1.2s;
  animation-fill-mode: forwards;

  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center center;
  background-image: url("../../../assets/images/animationDrag/pad/snapshells.svg");

  @media (max-width: $media-mobile-table-2) {
    right: -150px;
  }

  @media (max-width: $media-mobile-phone) {
    top: 75%;
    right: -130px;
  }

  @media (max-width: $media-mobile-phone) {
    top: 65%;
    right: -105px;

    width: 71px;
    //top: 8px;
    //left: 41px;
  }
}

.hintProduct::before {
  content: "";
  display: block;
  padding-top: 157.74%;
}

.hintTrash {
  position: absolute;
  top: 115%;
  right: -300px;
  width: 54px;

  opacity: 0;

  animation-name: show-img;
  animation-delay: 500ms;
  animation-duration: 1.2s;
  animation-fill-mode: forwards;

  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center center;
  background-image: url("../../../assets/images/icon/trash.svg");

  @media (max-width: $media-mobile-table-2) {
    right: -220px;
  }

  @media (max-width: $media-mobile-phone) {
    width: 28px;

    top: 125%;
    right: -150px;
  }

  @media (max-width: $media-mobile-phone) {
    top: 160%;
    right: -140px;
  }
}

.hintTrash:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.cursor {
  display: flex;
  justify-content: center;

  margin-bottom: 35px;

  animation-name: hide-img;
  animation-delay: 500ms;
  animation-duration: 1.2s;
  animation-fill-mode: forwards;
}

.cursorHint {
  position: relative;
  width: 5.8%;
  min-width: 44px;

  flex-shrink: 0;
}

.cursorHint::before {
  content: "";
  display: block;
  padding-top: 81.81%;
}

.cursorHintImg {
  max-height: 100%;
  max-width: 100%;
}

.kind {
  display: flex;
  justify-content: center;

  animation-name: hide-img;
  animation-delay: 500ms;
  animation-duration: 1.2s;
  animation-fill-mode: forwards;
}

.kind__list {
  margin: 0;
  padding: 0;
  list-style: none;

  width: 100%;

  display: flex;
  justify-content: center;
}

.kind__listItem {
  position: relative;

  width: 12.4%;
  flex-shrink: 0;

  min-width: 62px;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.kind__listItem::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.kind__listItem:not(:last-child) {
  margin-right: 40px;

  @media (max-width: $media-mobile-phone) {
    margin-right: 20px;
  }
}

@keyframes hide-img  {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes show-img  {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
