@import "../../assets/sass/global/variable-media";

.product__navItem {
  width: calc(100% / 3);
}

.product__navLink {
  appearance: none;
  width: 100%;
  height: 100%;

  position: relative;

  display: inline-flex;
  justify-content: flex-start;
  flex-direction: column;

  padding-top: 10px;
  padding-right: 12px;
  padding-bottom: 10px;
  padding-left: 12px;

  color: #666666;
  cursor: pointer;

  background-color: transparent;
  border: none;

  border-right: 1px solid var(--tough-gray-2);
  border-bottom: 1px solid var(--tough-gray-2);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 6px;
    width: 0;
    background-color: transparent;
    transition: 250ms;
  }

  @media (max-width: $media-mobile-table-2) {
    padding-top: 6px;
    padding-bottom: 6px;

    border-top: 1px solid var(--tough-gray-2);
  }
}

.product__navLink:disabled {
  opacity: 0.5;
  color: #bebdbd;
}

.product__navLink:disabled:hover {
  cursor: default;
}

.product__navLink_active {
  border: 1px solid var(--tough-black);
}

.product__navLink_active .product__navInfo {
  color: var(--tough-black);
}

.product__navLink_active .product__navNumber {
  color: var(--tough-black);
}

.product__navLink_active::before {
  width: calc(100% + 2px);
  background-color: var(--tough-yellow);
  border: 1px solid var(--tough-black);
  border-top: none;
  height: 6px;

  @media (max-width: $media-mobile-table) {
    height: 4px;
  }
}

.product__navNumber {
  font-size: 1.125rem;
  font-weight: 900;

  margin-right: 8px;

  @media (max-width: $media-mobile-phone) {
    font-size: 16px;
    margin-bottom: 0px;
  }
}

.product__navInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  text-transform: uppercase;
}

.product__navInfoCaption {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 1px;
  font-weight: 200;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  @media (max-width: $media-mobile-table) {
    font-size: 11px;
  }
}
