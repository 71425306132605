@import "../../assets/sass/global/variable-media";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;

  background: var(--tough-yellow);
  border: 1px solid var(--tough-brown-3);

  color: var(--tough-black);
  font-weight: 700;
  font-size: 16px;
}
