@import "../../assets/sass/global/variable-media";

.innerPopupClasses {
  position: relative;

  height: 396px;
  background-color: var(--tough-white);

  padding: 60px;

  width: 100%;
  max-width: 515px;

  margin-right: 16px;
  margin-left: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: $media-mobile-table) {
    padding-right: 20px;
    padding-bottom: 40px;
    padding-left: 20px;
  }

  @media (max-width: $media-mobile-phone) {
    padding-right: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
  }
}

.caption {
  font-size: 22px;
  line-height: 28px;
  font-weight: 700;
  text-transform: uppercase;

  text-align: center;

  margin-bottom: 30px;
}

.share {
  margin-bottom: 29px;
  width: 100%;

  @media (min-width: $media-mobile-phone) and (max-width: $media-mobile-table) {
    width: 360px;
  }
}

.shareRow:not(:last-child) {
  margin-bottom: 10px;
}

.shareInput {
  padding-top: 10px;
  padding-right: 16px;
  padding-bottom: 10px;
  padding-left: 16px;

  width: 100%;
  min-width: unset;
  height: 44px;
  border: 1px solid #B3B3B3;

  text-align: left;

  font-size: 16px;
}

.shareInput::placeholder {
  color: #B3B3B3;

  text-align: left;
  text-transform: uppercase;
}

.shareAccept {
  appearance: none;
  height: 100%;

  display: flex;
  justify-content: center;

  width: 100%;

  text-transform: uppercase;
  font-weight: 700;

  padding-top: 10px;
  padding-bottom: 10px;

  border: 1px solid var(--tough-brown-2);
  background-color: var(--tough-orange);

  cursor: pointer;
  color: var(--tough-black);
}

.copy {
  display: flex;
  justify-content: center;
}

.copyBtn {
  appearance: none;

  border: none;

  padding-top: 2px;
  padding-right: 5px;
  padding-bottom: 2px;
  padding-left: 26px;

  cursor: pointer;

  background-color: transparent;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: auto 100%;
  background-image: url("../../assets/images/icon/copy-gray.svg");

  color: #666666;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: underline;
}

.hint {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%) translateY(60px);

  background-color: var(--tough-black);

  text-transform: uppercase;
  font-size: 0.75rem;
  color: var(--tough-white);

  padding-top: 8px;
  padding-right: 30px;
  padding-bottom: 8px;
  padding-left: 30px;
}

.shareBlock {
  margin-bottom: 10px;
}

.shareBtn {
  position: relative;
  display: inline-flex;
  width: 31px;
  flex-shrink: 0;
}

.shareBtn::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.shareBtn:not(:last-child) {
  margin-right: 20px;
}

.shareBtn svg {
  max-height: 100%;
  max-width: 100%;
}

.shareRowButton {
  height: 40px;
  min-height: 40px;
  @media (min-width: $media-mobile-phone) and (max-width: $media-mobile-table) {
    margin-top: 20px;
  }
}
