@import "../../../../assets/sass/global/variable-media.scss";

.title {
  text-transform: uppercase;
  font-weight: 950;
  font-size: 38px;
  line-height: 48px;
  color: #000000;
  margin: 0;
  float: left;
  margin-right: 14px;

  @media (max-width: $media-mobile-table) {
    display: none;
  }
}

.productTypeLabel {
  font-style: normal;
  font-weight: 300;
  font-size: 38px;
  line-height: 44px;
  text-transform: uppercase;
  color: #666666;

  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 14px;

  cursor: pointer;

  @media (max-width: $media-mobile-table) {
    font-weight: 950;
    color: #000000;
  }

  @media (max-width: $media-mobile-table-2) {
    font-weight: 700;
    color: #000000;
    font-size: 22px;
    padding-left: 13px;
  }
}

.productTypeOption {
  font-size: 22px;
  min-width: 200px;
  height: 26px;
  transition: all 0.5s;
  line-height: 26px;
  margin-bottom: 14px;

  @media (max-width: $media-mobile-table) {
    font-weight: 200;
    margin-bottom: 21px;
  }

  @media (max-width: $media-mobile-table-2) {
    font-size: 18px;
    min-width: 100px;
    font-weight: 200;
    line-height: 24px;
    margin-bottom: 10px;
  }

  span {
    &:hover {
      color: #000000;
      font-weight: 900;
      font-size: 32px;
      line-height: 36px;
      height: 36px;
      transition: all .1s cubic-bezier(.19,1,.22,1);
  
      @media (max-width: $media-mobile-phone) {
        font-size: 22px;
      }
    }
  }
}

.arrow {
  width: 20px;
  height: 9px;
}

.dropdown {
  position: relative;
  display: inline-block;
  padding-top: 4px;
}

.row {
  display: flex;
  flex-direction: row;
}

.dropdownContent {
  position: absolute;
  background-color: var(--tough-white);
  min-width: 160px;
  height: 160px;
  padding-left: 282px;
  padding-bottom: 22px;
  padding-right: 20px;
  padding-top: 4px;
  z-index: 1;
  visibility: visible;
  transition: height 0.5s;

  @media (max-width: $media-mobile-table) {
    padding: 0px;
    padding-top: 21px;
    min-width: 120px;
    margin: 0px;
  }

  @media (max-width: $media-mobile-table-2) {
    padding: 0px;
    padding-top: 12px;
    min-width: 120px;
    margin: 0px;
  }

  @media (max-width: $media-mobile-phone) {
    padding: 0px;
    min-width: 120px;
    margin: 0px;
  }
}

.hideContent {
  visibility: hidden;
  height: 0;
  transition: height 0.5s;
  opacity: 0;
}

.productTypeLabelHide {
  height: 0px;
  transition: height 0.5s;
}